<template>
	<v-container fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading :title="title">
          <base-btn v-if="branchOffices.length>0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12">
				<base-data-table 
          v-model="sortBy"
					:headers="headers" 
					:items="branchOffices" 
          :showDialog="dialog"
          showEnabledField
					@loadDataTable="toList"
          @newItem="open"
        > 
          <template v-slot:item.options="{ item }">
            <base-icon-btn @click="editItem(item)" icon="mdi-pencil" tooltip="Editar"></base-icon-btn>
            
            <template v-if="!item.main" >
              <template v-if="item.enabled">
                <base-icon-btn color="red darken-1" @click="enableDisableItem(item, 2)" icon="mdi-cancel" tooltip="Desactivar"></base-icon-btn>
              </template>
              <template v-else>
                <base-icon-btn color="green darken-1" @click="enableDisableItem(item, 1)" icon="mdi-check" tooltip="Activar"></base-icon-btn>
              </template>
            </template>
          </template>

          <template v-slot:content>
            <base-form-stepper ref="BranchForm"
              :formTitle="formTitle"
              :step="step"
              :steps="steps"
              :formLoading="formLoading"
              @prevStepButton="step--"
              @nextStepButton="step++"
              @cancelButton="close"
              @saveButton="save"
            >

              <template v-slot:formContent-1>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <base-text-field v-model="editedItem.visualName" label="Nombre" counter maxlength="100" 
                    :rules="[commonValRules.required, validationRules.visualNameRules.counter]" required :disabled="editedItem.main"></base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.countryId" :items="countries" label="País" @change="loadCities"
                    :rules="[commonValRules.required]" required></base-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.cityId" :items="availableCities" label="Ciudad" 
                    :rules="[commonValRules.required]" required></base-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <base-text-field v-model="editedItem.address" label="Dirección" counter maxlength="300" 
                    :rules="[commonValRules.required, validationRules.addressRules.counter]" required></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-time-range-picker-dialog v-model="editedItem.customerServiceSchedule" label="Horario de atención" counter maxlength="100" 
								    :rules="[commonValRules.required, validationRules.customerServiceScheduleRules.counter]" required></base-time-range-picker-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-number-field fullWidth v-model="editedItem.phoneNumber" label="Teléfono" counter maxlength="25"
                    :rules="[validationRules.phoneNumberRules.counter]"></base-number-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <base-textarea outlined v-model="editedItem.details" label="Detalles" counter maxlength="500" 
                    :rules="[validationRules.detailsRules.counter]"></base-textarea>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:formContent-2>
                <v-row no-gutters>
                  <v-col cols="12">
                    <base-map ref="BranchMap" v-if="dialog" v-model="editedItem.coordinates" title="Indique la ubicación de la sucursal." :searchAddress="searchMapAddress"/>
                  </v-col>
                </v-row>
              </template>

            </base-form-stepper>
          </template>
				</base-data-table>
			</v-col>
		</v-row>

    <base-pdf-viewer :show="showPdfViewer" :title="title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
  mixins: [MainExternalData],
  
	data() {
		return {
      title: 'Sucursales',
      step:1,
      steps:[
        {value: 1, text:'Datos Iniciales'},
        {value: 2, text:'Geolocalización'},
      ],
			sortBy: 'visualName',
			branchOffices: [],
			headers: [
        { text: "Opciones", value: "options", sortable: false },
        { text: "Nombre", value: "visualName" },
        { text: "País", value: "country", sortable: false},
        { text: "Ciudad", value: "city", sortable: false},
        { text: "Dirección", value: "address", sortable: false},
        { text: "Horario Atención", value: "customerServiceSchedule", sortable: false},
        { text: "Teléfono", value: "phoneNumber", sortable: false},
        { text: "Estado", value: "enabled"}
			],
			countries:[],
      cities:[],
      availableCities:[],
			editedItem: {
        id: '',
        visualName: '',
				countryId: '',
				cityId: '',
				address: '',
				customerServiceSchedule: '',
				coordinates: '',
				phoneNumber: '',
				details: '',
        main: false,
      },
      defaultItem: {
        id: '',
        visualName: '',
				countryId: '',
				cityId: '',
				address: '',
				customerServiceSchedule: '',
				coordinates: '',
				phoneNumber: '',
				details: '',
        main: false,
			},
      editData: false,
      dialog: false,
      formLoading: false,
      validationRules: {
        visualNameRules: {
          counter: value => (value.length >= 5 && value.length <= 100) || 'No debe tener más de 100 caracteres, ni menos de 5 caracteres.',
        },
        addressRules: {
          counter: value => (value.length <= 300) || 'No debe tener más de 300 caracteres.',
				},
				customerServiceScheduleRules: {
          counter: value => (!!value && value.length <= 100) || 'No debe tener más de 100 caracteres.',
				},
				coordinatesRules: {
          counter: value => (value.length <= 150) || 'No debe tener más de 150 caracteres.',
				},
				phoneNumberRules: {
          counter: value => (value.length <= 25) || 'No debe tener más de 25 caracteres.',
				},
				detailsRules: {
          counter: value => (value.length <= 500) || 'No debe tener más de 500 caracteres.',
				},
      },

      //PDF report
			showPdfViewer: false,
			reportFile:{
				fileContent:'',
				fileName:''
			},
			defaultReportFile:{
				fileContent:'',
				fileName:''
			},
		}
  },
  
  computed: {
		formTitle() {
      return this.editData ? "Actualizar datos" : "Nuevo registro";
    },
    
    searchMapAddress(){
      if (!this.editData) {
        this.editedItem.coordinates = ''
      }
      
			if (this.editedItem.countryId > 0 && this.editedItem.cityId) {
				const country = this.countries.find(x => x.value == this.editedItem.countryId).text
        const city = this.cities.find(x => x.id == this.editedItem.cityId).visualName
				return `${city}, ${country}`
			} 
			return null
		}
	},

	created() {
    this.listOfCountries();
    this.listOfCities();
		this.toList();
  },

	methods: {
    async listOfCountries(){
      let me=this;
			var countriesArray=[];
			await me.getObjectResponse('api/Parameter/Countries', me.baseRequest).then(data => {
				if (data !== undefined) {
					countriesArray = data.countries; 
					countriesArray.map(function(x){
            me.countries.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },
    
    async listOfCities(){
      let me=this;
			await me.getObjectResponse('api/Parameter/Cities', me.baseRequest).then(data => {
				if (data !== undefined) {
					me.cities = data.cities; 
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    loadCities(){
      this.availableCities=[];
      this.editedItem.cityId='';
      if (!this.editedItem.countryId || this.editedItem.countryId == '') {
        return;
      }

      const available = this.cities.filter(x => x.countryId === this.editedItem.countryId);
      var result =[];
      available.map(function(x){
        result.push({text:x.visualName, value:x.id});
      });
      this.availableCities=result
    },

		async toList(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.branchOffices=[];
        await me.getObjectResponse('api/BranchOffice/GetAll', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.branchOffices = data.branchOffices; 
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async editItem(item) {
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getObjectResponse('api/BranchOffice/GetById', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedItem = Object.assign({}, data.branchOffice);
          me.loadCities();
          me.editedItem.cityId = data.branchOffice.cityId
          me.editData = true;
          me.dialog = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    open(){
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.clean();
    },
    
    clean(){
      this.step=1;
      this.editData = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.BranchForm.reset();
      this.$refs.BranchMap.reset();
    },

    async save() {
      if (!this.$refs.BranchMap.validate()) {
        return
      }
      
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        if (me.editData) {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'id': me.editedItem.id,
            'visualName': me.editedItem.visualName,
            'countryId': me.editedItem.countryId,
            'cityId': me.editedItem.cityId,
            'address': me.editedItem.address,
            'customerServiceSchedule': me.editedItem.customerServiceSchedule,
            'coordinates': me.editedItem.coordinates,
            'phoneNumber': me.editedItem.phoneNumber,
            'details': me.editedItem.details,
          };
          await me.getObjectResponse('api/BranchOffice/Update', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.branchOffice);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        } 
        else {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'visualName': me.editedItem.visualName,
            'countryId': me.editedItem.countryId,
            'cityId': me.editedItem.cityId,
            'address': me.editedItem.address,
            'customerServiceSchedule': me.editedItem.customerServiceSchedule,
            'coordinates': me.editedItem.coordinates,
            'phoneNumber': me.editedItem.phoneNumber,
            'details': me.editedItem.details,
          };
          await me.getObjectResponse('api/BranchOffice/Create', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.branchOffice);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        }
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    addOrUpdateListItem(item){
			let me=this;
			if (!!me.branchOffices.find((x) => x.id === item.id)) {
				const index = me.branchOffices.findIndex((x) => x.id === item.id)
				me.branchOffices.splice(index, 1, item)
			} else {
				me.branchOffices.push(item);
			}
		},

    async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `la sucursal <strong>${item.visualName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
		},

    async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/BranchOffice/Enable', request).then(data => {
          if (data) {
            item.enabled = true;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/BranchOffice/Disable', request).then(data => {
          if (data) {
            item.enabled = false;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/BranchOffice/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>